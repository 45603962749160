<template>
  <div style="background: #fff;">
      <van-nav-bar title="选择项目" left-text="返回" @click-left="back()" fixed left-arrow/>
      <van-search v-model="value" placeholder="请输入项目名称" @clear="sea()" @blur="sea()" @search="sea()" style="margin-top: 45px;"/>
      <!-- <van-radio-group v-model="checkeds">
          <div v-for="item in project" :key="item.id" class="prejectList">
            <van-radio :name="item">{{item.projectName}}</van-radio>
          </div>
      </van-radio-group> -->
      <div class="list" v-for="item in project" :key="item.id" @click="addts(item)">{{item.projectName}}</div>
      <div v-if="project.length == 0" class="adds">暂无数据</div>
      <div style="width: 100%;height: 44px;"></div>
      <!-- <div class="qued" @click="qued()">确定</div>
       -->
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {
        value: '',
        project: '',
        checkeds: '',
        pro: '',
        from: '',
        idx: 0,
        dingshiqiL: null
    };
  },
  computed: {},
  watch: {
      '$route': 'getParams',
      value(val) {
          var that = this
          if(that.dingshiqiL) {
              clearTimeout(that.dingshiqiL)
          }
          that.dingshiqiL = setTimeout(() => {
            that.sea()
          }, 500)
      }
  },
  created() {
      this.getParams()
  },
  mounted() {
      this.getPeoject()
  },
  methods: {
      // 接收传过来的参数
      getParams() {
          const routerParams = this.$route.query.from
          const idx = this.$route.query.idx
          this.from = routerParams
          this.idx = idx
      },
      // 获取项目
      getPeoject() {
          this.$axios.post("/project/getProjectList", {})
          .then(res => {
              if(res.code == "ok") {
                  this.project = res.data;
                  this.pro = res.data
                  console.log(res.data)
              } else {
                  this.$toast.fail('获取失败:'+res.msg);
              }
          }).catch(err=> {this.$toast.clear();});
      },
      back() {
          this.$router.go(-1);
      },
      addts(item) {
          this.checkeds = item
          this.qued()
      },
      qued() {
          console.log(this.checkeds)
          if(this.checkeds == '') {
              this.$toast('请选择项目');
          } else {
              this.from.domains[this.idx].projectId = this.checkeds.id
              this.from.domains[this.idx].projectName = this.checkeds.projectName
              localStorage.setItem('formVal', JSON.stringify(this.from))
              this.$router.go(-1);
          }
      },
      sea() {
          console.log(1234567)
          if(this.value.length > 0) {
              let text = this.value
              let reg = new RegExp(text)
              let data = this.project.filter(item => reg.test(item.projectName)) //返回
              this.project = data
          } else {
              this.project = this.pro
          }
          
      },
  },
};
</script>

<style>
    .prejectList {
        padding: 10px 20px;
        background: #fff;
        border-bottom: 1px solid #f6f6f6;
    }
    .van-search {
        background: #fff !important;
        border-bottom: 1px solid #f6f6f6;
    }
    .van-search__content {
        border-radius: 20px !important;
    }
    .qued {
        width: 100%;
        height: 44px;
        background: #1989fa;
        text-align: center;
        line-height: 44px;
        color: #fff;
        position: fixed;
        left: 0;
        bottom: 0;
    }
    .adds {
        width: 100%;
        text-align: center;
        line-height: 85px;
        color: #b6b6b6;
    }
    .list {
        padding: 15px 20px;
        border-bottom: 1px solid #f6f6f6;
    }
</style>
